.input{
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background: #E6E8EB;
}
.input textarea{
    font-family: 'Montserrat';
    -moz-appearance: none;
    resize: none;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.1px;
    color: #051932;
    padding: 22px 12px 14px 12px;
    width: calc(100% - 24px);
    margin-bottom: -4px;
}
.input>label{
    position: absolute;
    top: 18px;
    left: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #051932;
    transition: all ease 0.3s;
}

.input>.label-hide{
    font-size: 10px;
    line-height: 10px;
    top: 8px;
    opacity: 0.6;
}