.bk-area-file{
    border: 2px dashed #CFD3D9;
    border-radius: 12px;
    padding: 52px 12px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.bk-file__title{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #051932;
}
.bk-file__desc{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #051932;
}
.bk-area-file__placeholder{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 24px);
    height: calc(100% - 104px);
    background-color: rgba(0,192,209,.5);
    border-radius: 12px;
    padding: 52px 12px;
}
.bk-area-file__pl{
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #fff;
}
.bk-file__preview{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}