.bk-area-text{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #051932;
    opacity: 0.8;
    width: 100%;
    text-align: left;
}