.bk-grid-items__flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bk-grid-items__content{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(230px,1fr));
    grid-gap: 20px 10px;
    margin-top: 20px;
}
.bk-area-grid-items{
    margin-top: 40px;
}
.bk-grid-items__image{
    border-radius: 12px;
    height: 130px;
    background: #A3AAB4;
}
.bk-grid-items__card{
    cursor: pointer;
}
.bk-grid-items__image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}