.bk-modal-main{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10000;
}
.bk-modal-main__content{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 12px;
    padding: 40px;
    max-width: 710px;
    width: 100%;
    margin: 0 15px;
}