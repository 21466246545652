.bk-button{
    padding: 10px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    background: #00C0D1;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.bk-button__delete{
    background: #d1003b;
}