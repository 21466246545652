.bk-area-chapter-item{
    border-bottom: 1px solid #E6E8EB;
    padding: 16px 16px;
    cursor: pointer;
}
.bk-chapter-item__flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bk-chapter-item__more{
    position: relative;
    overflow: visible;
}