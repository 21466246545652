.bk-area-profile-item{
    border-bottom: 1px solid #E6E8EB;
    padding: 16px 16px;
    cursor: pointer;
}
.bk-profile-item__flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bk-profile-item__flex>svg{
    min-width: 20px;
}
.bk-profile-item__photo{
    background: #FFFFFF;
    border: 1px solid rgba(5, 25, 50, 0.1);
    min-width: 26px;
    height: 26px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    margin-right: 8px;
}
.bk-profile-item__photo>svg{
    position: absolute;
    top: 3px;
    left: 3px;
}
.bk-profile-item__photo>svg>path{
    fill: #A3AAB4;
}