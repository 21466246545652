@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Extra Bold"), local("Montserrat-Extra-Bold"),
  url("./assets/fonts/Montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
url("./assets/fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff"),
url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
  url("./assets/fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
url("./assets/fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
url("./assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Semi Bold"), local("Montserrat-Semi-Bold"),
  url("./assets/fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
url("./assets/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
  url("./assets/fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
url("./assets/fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg{
  vertical-align: middle;
}
