.bk-area-title{
    padding: 0 26px;
}
.bk-area-title>textarea{
    height: 57px;
}
.bk-area-title>textarea, .bk-area-title>div{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #051932;
    outline: none;
    border: none;
    text-align: left;
    -moz-appearance: none;
    resize: none;
    width: 100%;
    padding: 0;
    background: #fff;
}