.bk-area-middle{
    max-width: 900px;
    margin: 0 auto;
}
.bk-area-content{
    margin-left: 360px;
}
.bk-area-chapter-outlet__head{
    background-color: #A3AAB4;
    height: 360px;
}
.bk-text-middle{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #051932;
}
.bk-text-empty{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #051932;
    margin-bottom: 20px;
}
.bk-area-chapter-outlet{
    margin-top: 97px;
}
.bk-grid-items__empty{
    background: #EFF6F8;
    border-radius: 12px;
    padding: 20px;
    max-width: 710px;
}
.bk-area-new-chapter{
    max-width: 900px;
    margin: 0 auto;
}
.bk-new-chapter__title{
    margin-top: 40px;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #051932;
    text-align: left;
}
.bk-new-chapter__content{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.bk-new-chapter__left{
    margin-right: 20px;
    width: 40%;
}
.bk-new-chapter__right{
    width: 60%;
}
.bk-area-chapter-outlet__head{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.bk-area-middle__flex{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(5, 25, 50, 0.4);
}
.bk-area-middle__flex>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 900px;
    margin: 0 auto;
}
.bk-chapter__title{
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #FFFFFF;
    margin-bottom: 10px;
    text-align: left;
}
.bk-chapter__desc{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    margin-bottom: 40px;
    text-align: left;
}
.bk-chapter-outlet__header{
    border-bottom: 1px solid #E6E8EB;
    position: fixed;
    top: 0;
    width: calc(100% - 400px);
    right: 0;
    padding: 18px 20px;
    background: #fff;
}
.bk-chapter-header__flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bk-chapter-header__icons{
    display: flex;
    align-items: center;
}
.bk-chapter-header__icons>div{
    vertical-align: middle;
    height: 20px;
    cursor: pointer;
}
.bk-chapter-header__icon-right>svg{
    transform: rotate(180deg);
}
.bk-chapter-header__icon-right{
    margin-left: 12px;
}
.bk-chapter-header__name{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.1px;
    color: #051932;
    opacity: 0.8;
    width: 100%;
    margin-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
.bk-head-return-item__search, .bk-head-return-item__left, .bk-chapter-header__icon{
    height: 20px;
}
.bk-chapter-header__editmode, .bk-chapter-header__console{
    display: flex;
    align-items: center;
}
.bk-chapter-header__editmode{
    margin-right: 20px;
    cursor: pointer;
}
.bk-chapter-header__console{
    margin-left: 20px;
}
.bk-chapter-header__icon>svg{
    vertical-align: baseline;
}
.bk-chapter-header__editmode-title{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: #051932;
    display: block;
    width: 100%;
    white-space: nowrap;
    margin-left: 6px;
}
.bk-chapter-header__icon-more{
    cursor: pointer;
    overflow: visible;
}