.bk-area-menu{
    max-width: 360px;
    width: 100%;
    background: #F4F4F4;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 1000;
}
.bk-area-chapter-item:hover{
    background: #ececec;
}