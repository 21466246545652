.bk-area-head-item{
    padding: 16px 20px;
    border-bottom: 1px solid #E6E8EB;
}
.bk-head-item__flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bk-head-item_text-logo{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #051932;
    letter-spacing: 0.15px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}
.bk-head-item_text-logo>span{
    color: #00C0D1;
}
.bk-area-head-item__two{
    display: none;
}
.bk-head-item__menu{
    display: none;
    margin-right: 12px;
}