.bk-area-context-menu{
    background: #fff;
    padding: 12px;
    position: absolute;
    box-shadow: 0px 10px 40px rgba(5, 25, 50, 0.2);
    border-radius: 8px;
    min-width: 240px;
    z-index: 1001;
    display: none;
}
.bk-context-menu__open{
    display: block;
}
.bk-context-menu__item{
    padding: 8px;
}
.bk-context-menu__item:hover{
    background: #F4F4F4;
    border-radius: 4px;
}