.bk-modal__area-title{
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #051932;
    text-align: left;
    margin-bottom: 12px;
}
.bk-modal__area-desc{
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: #051932;
    margin-bottom: 12px;
}
.bk-modal__area-button{
    text-align: right;
}